


















































































































@use "../css/variables.scss";

.link {
  color: variables.$color-red-100;
}
